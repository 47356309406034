import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import BootrapVue from 'bootstrap-vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import money from 'v-money'
import VueCroppie from 'vue-croppie'
import i18n from '@/libs/i18n'
import '@/libs/base64'
import Rollbar from 'rollbar';

import Vidle from 'v-idle'
import router from './router'
import App from './App.vue'
import store from './store'
import './filters'
import { version } from '../package.json'

import 'vue-resize/dist/vue-resize.css'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// Axios Mock Adapter
import '@/@fake-db/db'

// eslint-disable-next-line
import('croppie/croppie.css')

Vue.component('VueTypeaheadBootstrap', VueTypeaheadBootstrap)

// Composition API
Vue.use(VueCompositionAPI)
// Bootstrap Vue
Vue.use(BootrapVue)

Vue.use(Vidle)

Vue.use(VueCroppie)

// register directive v-money and component <money>
Vue.use(money, { precision: 4 })

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$rollbar = new Rollbar({
  accessToken: 'c1fe746d804b4d69a3463eb039885853',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    // Track your events to a specific version of code for better visibility into version health
    code_version: version,
  }
});

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
